import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { transfer } from '../../../database/actions/account';
import { getAccount } from '../../../database/actions/account';
import '../styles/transfer.css';
import CurrencyConverter from '../../others/CurrencyConverter';

const Transfer = () => {
  const { account } = useSelector((state) => state.accounts);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [transValue, setTransValue] = useState({ reciever: '', bank: '', accNum: '', bankAdd: '', amount: '', charge: '', payable: '', total: '', text: '', wallet: '' });
  const [restUser, setRestUser] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAccount(user.result._id))
  }, [dispatch, user]);

  useEffect(() => {
    let percent = 0;
    let payable = 0;
    if (transValue.amount > 30) {
      percent = (transValue.amount * 0.0125).toFixed(3);
      payable = Number(transValue.amount) + Number(percent);
      setTransValue({ ...transValue, total: payable, charge: percent, payable: payable });
    }
  }, [transValue.amount]);

  const handleTransf = async (e) => {
    e.preventDefault();

    // let balance = Number(user.result.transaction.balance.mainAccount)
    // balance = Number(user.result.transaction.balance.mainAccount) - Number(transValue.payable)

    const formData = {
      "recName": `${transValue.reciever}`,
      "recBank": `${transValue.bank}`,
      "accNum": `${transValue.accNum}`,
      "address": `${transValue.bankAdd}`,
      "amount": Number(transValue.amount),
      "alertType": `Debit`,
      "charge": `+ ${transValue.charge}`,
      "total": Number(transValue.total),
      "details": `Transfer`,
      "status": `Pending`,
    }

    if (account?.transaction?.balance?.mainAccount < transValue.payable) {
      setTransValue({ text: "Low Balance" });
    }
    else if (account?.transaction?.balance?.mainAccount > transValue.payable) {
      setTransValue({ text: " " });
      dispatch(transfer(user.result._id, formData, navigate, user));
    }
  }

  return (
    <>
      <div className="transfer">
        <div className="trans-header">
          <h2>Transfer</h2>
        </div>
        <div className="trans-cont">
          <form onSubmit={handleTransf}>
            <select name="wallet" id="wallet" value={transValue.wallet} onChange={(e) => setTransValue({ ...transValue, wallet: e.target.value })} required>
              <option value="Personal Account">Main Account ({`${account?.transaction?.balance?.mainAccount} ${account?.currency}`})</option>
            </select>
            <div className="form-cont transfer-input">
              <input type="text" name="reciever" id="reciever" value={transValue.reciever} onChange={(e) => setTransValue({ ...transValue, reciever: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="reciever">Recipient's Name</label>
            </div>
            <div className="form-cont transfer-input">
              <input type='text' name="accNum" id="accNum" value={transValue.accNum} onChange={(e) => setTransValue({ ...transValue, accNum: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="accNum">Account Number </label>
            </div>
            <div className="form-cont transfer-input">
              <input type='text' name="bank" id="bank" value={transValue.bank} onChange={(e) => setTransValue({ ...transValue, bank: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="bank">Recipient's Bank </label>
            </div>
            <div className="form-cont transfer-input">
              <input type='text' name="bankAdd" id="bankAdd" value={transValue.bankAdd} onChange={(e) => setTransValue({ ...transValue, bankAdd: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="bankAdd">Bank Address </label>
            </div>
            <div className="form-cont transfer-input">
              <input type='number' name="amount" id="amount" value={transValue.amount} onChange={(e) => setTransValue({ ...transValue, amount: e.target.value })} required />
              <div className="underline"></div>
              <label htmlFor="amount">Amount <span>gas fee (+1.25%) </span></label>
            </div>
            <div className="total">
              <h3>{
                transValue.total ?
                  <CurrencyConverter
                    currency={account?.currency}
                    amount={transValue.total}
                  /> : '0'
                  } <span>Total</span></h3>

            </div>
            {
              (account?.status === "Restricted") ?
                <h3 onClick={() => setRestUser(true)} className='btn-hover'>Request</h3> :
                <button type="submit">Transfer</button>
            }
          </form>
        </div>
        <div className={"pop-up-cont" + (restUser ? " openpop" : " ")}>
          <div className={"pop-up" + (restUser ? " openVen" : " ")}>
            <h5>Dear valued customer</h5>
            <h5> We have detected unusual activity on your account, resulting in an increased number of transactions. To protect your account, we have temporarily restricted access to your account.</h5>
            <h5>Your account security is our top priority. Contact Support Team.</h5>
            <button onClick={() => setRestUser(false)} >Close</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Transfer