import React from 'react'
import moment from 'moment';
import CurrencyConverter from '../../others/CurrencyConverter';
import { useSelector } from 'react-redux';

const TransDets = ({ currentRept, Cancel }) => {
    const { account } = useSelector((state) => state.accounts);

  return (
    <>
      <div className="tsctn-dets " >
        <div className="detailed-trans">
          <div className="dtt">
            <h4>Transaction ID</h4>
            <p className='idt'>FFFC/{currentRept?.option?._id}</p>
          </div>
          <div className="dtt">
            <h4>Date</h4>
            <p className='tID'>{moment(currentRept?.option?.date).format('LLL')}</p>
          </div>
          <div className="dtt">
            <h4>Recipient Name</h4>
            <p className='reptName'>{currentRept?.option?.recName}</p>
          </div>
          <div className="dtt">
            <h4>Recipient Bank</h4>
            <p>{currentRept?.option?.recBank}</p>
          </div>
          <div className="dtt">
            <h4>Account/Routing Number</h4>
            <p>{currentRept?.option?.accNum}</p>
          </div>
          <div className="dtt">
            <h4>Address</h4>
            <p>{currentRept?.option?.address}</p>
          </div>
          <div className="dtt">
            <h4>Amount</h4>
            <p className={(currentRept?.option?.alertType === "Debit" || currentRept?.option?.alertType === "debit") ? "red" : "grn"}>
              <CurrencyConverter
                currency={account?.currency}
                amount={currentRept?.option?.amount}
              />
            </p>
          </div>
          <div className="dtt">
            <h4>Alert Type</h4>
            <p className={(currentRept?.option?.alertType === "Debit" || currentRept?.option?.alertType === "debit") ? "red" : "grn"}>{currentRept?.option?.alertType}</p>
          </div>
          <div className="dtt">
            <h4>Charge</h4>
            <p className='chrg'>
              <CurrencyConverter
                currency={account?.currency}
                amount={currentRept?.option?.charge}
              />
            </p>
          </div>
          <div className="dtt">
            <h4>Total</h4>
            <p>
              <CurrencyConverter
                currency={account?.currency}
                amount={currentRept?.option?.total}
              />
            </p>
          </div>
          <div className="dtt">
            <h4>Status</h4>
            <p className={"statz " + currentRept?.option?.status}>{currentRept?.option?.status}</p>
          </div>
          <div className="dtt">
            <h4>Details</h4>
            <p>{currentRept?.option?.details}</p>
          </div>
        </div>
        <h3 className="close" onClick={Cancel}>Close</h3>
      </div>
    </>
  )
}

export default TransDets