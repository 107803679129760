import React, { useState } from 'react'
import '../styles/dashboard.css'
import { Link } from 'react-router-dom'

import { FiCheckCircle } from "react-icons/fi";

import '../styles/dashboard.css'
import { useSelector } from 'react-redux';
import CurrencyConverter from '../../others/CurrencyConverter';
const Dashboard = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { accounts, account } = useSelector((state) => state.accounts);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <div className="dashboard">
        <div className="cont">
          <div className="act-header">
            <div className="act-numb">
              <h3>Welcome <span>{account?.userName}</span></h3>
              <h4>Account Number: <span> {account?.accountNumber}</span></h4>
            </div>
            <div className="act-balance">
              <h3>Your Balance:</h3>
              <h4>
                {<CurrencyConverter
                  currency={account?.currency}
                  amount={account?.transaction?.balance?.mainAccount}
                />}
              </h4>
            </div>
            <div className="act-dets">
              <h2>Account Status:</h2>
              <p className={(account?.status === "Active" ? " colG" : " colR")}>{account?.status}</p>
            </div>
          </div>
          <ul className="list-links">
            <li><Link to={`/${user?.result?.userName}/transfer`}>Quick Transfer</Link></li>
            <li><Link to={`/${user?.result?.userName}/transfer`}>Wire Transfer</Link></li>
            <li><Link to={`/${user?.result?.userName}/liveTrade`}>Stock Market</Link></li>
            <li><Link to={`/${user?.result?.userName}/withdrawal`}>Withdraw Funds</Link></li>
          </ul>
          <div className="loan-ads beforeWas">
            <h3>Get Loans</h3>
            <p>Installment Plan</p>
            <Link to={`/${user?.result?.userName}/LiveChat`}>Contact Support for Loans</Link>
          </div>
          <div className="all-bal">
            <ul>
              <h2>All Balance</h2>
              <li>
                <h4> Saving Account:</h4>
                <p>
                  {<CurrencyConverter
                    currency={account?.currency}
                    amount={account?.transaction?.balance?.savingsAccount}
                  />}
                </p>
              </li>
              <li>
                <h4>Current Account:</h4>
                <p>
                  {<CurrencyConverter
                    currency={account?.currency}
                    amount={account?.transaction?.balance?.currentAccount}
                  />}
                </p>
              </li>
              <li>
                <h4>Checking Account:</h4>
                <p>
                  {<CurrencyConverter
                    currency={account?.currency}
                    amount={account?.transaction?.balance?.checkingAccount}
                  />}
                </p>
              </li>
              <li>
                <h4>Interest:</h4>
                <p>
                  {<CurrencyConverter
                    currency={account?.currency}
                    amount={account?.transaction?.balance?.interest}
                  />}
                </p>
              </li>
              <li>
                <h4>Loan:</h4>
                <p>
                  {<CurrencyConverter
                    currency={account?.currency}
                    amount={account?.transaction?.balance?.loan}
                  />}
                </p>
              </li>
            </ul>
          </div>
          <div className="card-ads">
            <div className="card-ads-cont">
              <h4>Credit cards facility</h4>
              <h2>We provide all the credit card facilities to our customers.</h2>
              <p>We provide customers with a variety of credit cards based on the convenience of work.</p>
              <ul>
                <li><FiCheckCircle /><p>Standard credit cards</p></li>
                <li><FiCheckCircle /><p>Rewards credit cards</p></li>
                <li><FiCheckCircle /><p>Charge cards</p></li>
                <li><FiCheckCircle /><p>Subprime credit cards</p></li>
                <li><FiCheckCircle /><p>Limited purpose cards</p></li>
                <li><FiCheckCircle /><p>Balance transfer credit cards</p></li>
                <li><FiCheckCircle /><p>Student credit cards</p></li>
                <li><FiCheckCircle /><p>Secured credit cards</p></li>
                <li><FiCheckCircle /><p>Prepaid cards</p></li>
                <li><FiCheckCircle /><p>Business credit cards</p></li>
              </ul>
              <Link to={`/${user?.result?.userName}/card`}>Apply For Your Personalized Card</Link>
              <h5>Powered by Discover</h5>
            </div>
          </div>
          <div className="why-choose">
            <h4>Why choose us</h4>
            <h2>Our bank has been providing services to its customers for almost 25 years.</h2>
          </div>
          <div className="vision">
            <div className="img"></div>
            <div className="dets">
              <h2>First Franklin Financial Corporation Vision & Mission</h2>
              <p>We stand with and for, the fair and equal treatment of men, women and children regardless of race, gender, age, creed or nationality. Even with those guiding principles, we understand there is much anger and hurt all across
                our Global human right now with limited access to credit and banking. The pain we feel demonstrates a collective need to strengthen our global client communities~ both at the local and global level. First Franklin Financial Corporation, will be a part of that solution.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard