import React, { useEffect, useState } from 'react'

const CurrencyConverter = ({currency, amount}) => {
    const [convert, setConvert] = useState('')
    console.log(convert)
    useEffect(() => {
        let converted = 0
        if (currency === "USD"){
            converted = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(amount)
          } else if (currency === "EUR"){
            converted = new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            }).format(amount)
          } else if ( currency === "GBP"){
            converted = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP",
                minimumFractionDigits: 2,
              }).format(amount)
          } else if (currency === "RON" ){
            converted = Intl.NumberFormat('ro-RO', {
                style: 'currency',
                currency: 'RON',
                currencyDisplay: 'name',
              }).format(amount).replace('românești', '')
          }

          setConvert(converted)
    }, [amount, currency])
    console.log(convert)
    
  return (
    <>
    {convert ? `${convert}` : ``}
    </>
  )
}

export default CurrencyConverter